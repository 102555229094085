import { createReducer } from '@reduxjs/toolkit';
import { cartLoaded } from './actions';

const initialState: {
    cartProductQuantity?: number;
} = {};

const reducer = createReducer(initialState, builder => {
    builder.addCase(cartLoaded, (state, action) => {
        state.cartProductQuantity = action.payload;
    });
});

export default reducer;
