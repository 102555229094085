class CookieService {
    getCookieValue<T>(cookieKey: string): T | null {
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        const targetCookie = cookies.find(cookie => cookie.startsWith(`${cookieKey}=`));
        if (targetCookie) {
            const cookieValue = targetCookie.substring(cookieKey.length + 1);
            try {
                return JSON.parse(decodeURIComponent(cookieValue)) as T;
            } catch (error) {
                console.error(error);
                return null;
            }
        }
        return null;
    }
    
    set(cookieKey: string, value: object, expires: Date): void {
        document.cookie = `${cookieKey}=${JSON.stringify(value)}; expires=${expires.toUTCString()}; path=/;`;
    }

    remove(cookieKey: string): void {
        document.cookie = `${cookieKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
}

export default new CookieService();
