import { createReducer } from '@reduxjs/toolkit';
import { AdminUtilitiesResponse } from '../../types';
import { adminUtilitiesLoaded } from './actions';

const initialState: AdminUtilitiesResponse = {
    communities: [],
    emailLinks: {},
};

const reducer = createReducer(initialState, builder => {
    builder.addCase(adminUtilitiesLoaded, (state, action) => action.payload);
});

export default reducer;
