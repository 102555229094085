import { createReducer } from '@reduxjs/toolkit';
import { User, RewardsBalanceAndCurrency } from '../../types';
import { userBalanceLoaded } from './actions';

const initialState: {
    currentUser?: User;
    userCreditBalance?: RewardsBalanceAndCurrency;
} = {};

const reducer = createReducer(initialState, builder => {
    builder.addCase(userBalanceLoaded, (state, action) => {
        state.userCreditBalance = action.payload;
    });
});

export default reducer;
