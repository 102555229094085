import { createReducer } from '@reduxjs/toolkit';
import { setAdminToolsModalVisibility, setChangeCommunityModalVisibility, setUserInterestsModalVisibility, setUserIntroModalVisibility, setReloadInterests } from './actions';
import { setCommunityRegistrationModalVisibility } from './actions';
import { viewDataLoaded } from '../viewData/actions';
import { firstPersonDataStatusLoaded } from '../firstPersonDataStatus/actions';
import CookieService from '../../common/services/CookieService';
import { InterestModalCookie } from '../../types';
import { explicitInterestCaptureCookie } from '../../constants';

const initialState: {
    isChangeCommunityModalVisible: boolean;
    isCommunityRegistrationModalVisible: boolean;
    isAdminToolsModalVisible: boolean;
    isUserInterestModalVisible: boolean;
    isUserIntroModalVisible: boolean;
    isLoaded: boolean;
    isInterestsChanged: boolean;
} = {
    isChangeCommunityModalVisible: false,
    isCommunityRegistrationModalVisible: false,
    isAdminToolsModalVisible: false,
    isUserInterestModalVisible: false,
    isUserIntroModalVisible: false,
    isLoaded: false,
    isInterestsChanged: false,
};

const reducer = createReducer(initialState, builder => {
    builder.addCase(setChangeCommunityModalVisibility, (state, action) => {
        state.isChangeCommunityModalVisible = action.payload;
    });
    builder.addCase(setCommunityRegistrationModalVisibility, (state, action) => {
        state.isCommunityRegistrationModalVisible = action.payload;
    });
    builder.addCase(setAdminToolsModalVisibility, (state, action) => {
        state.isAdminToolsModalVisible = action.payload;
    });
    builder.addCase(setUserInterestsModalVisibility, (state, action) => {
        state.isUserInterestModalVisible = action.payload;
    });
    builder.addCase(setUserIntroModalVisibility, (state, action) => {
        state.isUserIntroModalVisible = action.payload;
    });
    builder.addCase(viewDataLoaded, (state, action) => {
        if (action.payload.displayTutorial) {
            state.isUserIntroModalVisible = true;
        } else if (action.payload.displayInterests && !CookieService.getCookieValue<InterestModalCookie>(explicitInterestCaptureCookie)) {
            state.isUserInterestModalVisible = true;
        }
    });
    builder.addCase(firstPersonDataStatusLoaded, (state, action) => {
        state.isLoaded = true;
        if (action.payload.displayEmailPrompt) {
            state.isUserIntroModalVisible = false;
            state.isUserInterestModalVisible = false;
        }
    });
    builder.addCase(setReloadInterests, (state, action) => {
        state.isInterestsChanged = action.payload;
    });
});

export default reducer;
