import { createReducer } from '@reduxjs/toolkit';
import { NavDropdownResponse, NavLinkResponse } from '../../types';
import { navItemsLoaded } from './actions';

const initialState: { items?: (NavLinkResponse | NavDropdownResponse)[] } = {};

const reducer = createReducer(initialState, builder => {
    builder.addCase(navItemsLoaded, (state, action) => {
        state.items = action.payload;
    });
});

export default reducer;
