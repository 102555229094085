const viewportBreakpoints = {
    xs: 0,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1600,
};

const elementIds = {
    quickSearchInputId: 'newQuickSearchInput',
    headerAppModalId: 'headerAppModal',
    loginId: 'loginContainer',
    pscModalId: 'pscModal',
};

const explicitInterestCaptureCookie = 'ExplicitInterestCapture';

const perkspotInternalCommunity = 924;

const chromeExtensionId = 'ecnfmmdoiiihbbpenbgdienmdpejbmdj';

export { viewportBreakpoints, elementIds, perkspotInternalCommunity, explicitInterestCaptureCookie, chromeExtensionId };
