import { createReducer } from '@reduxjs/toolkit';
import { LinkResponse } from '../../types';
import { popularCategoriesLoaded, recentlyViewedOffersLoaded, mostPopularSavingsLoaded } from './actions';

const initialState: {
    popularCategories?: LinkResponse[];
    recentlyViewedOffers?: LinkResponse[];
    mostPopularSavings?: LinkResponse[];
} = {};

const reducer = createReducer(initialState, builder => {
    builder.addCase(mostPopularSavingsLoaded, (state, action) => {
        state.mostPopularSavings = action.payload.map((saving) => ({
            id: saving.offerId,
            displayName: saving.description,
            imageUrl: saving.merchant.logoUrl,
            relativeUrl: saving.relativeUrl
        } ));
    });

    builder.addCase(recentlyViewedOffersLoaded, (state, action) => {
        state.recentlyViewedOffers = action.payload.map((offer) => ({
            id: offer.offerId,
            displayName: offer.description,
            relativeUrl: offer.relativeUrl,
            imageUrl: offer.merchant.logoUrl
        }));
    });

    builder.addCase(popularCategoriesLoaded, (state, action) => {
        state.popularCategories = action.payload;
    });
});


export default reducer;
