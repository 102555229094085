import { createReducer } from '@reduxjs/toolkit';
import { OktaWidgetConfig } from '../../types';
import { oktaWidgetConfigLoaded } from './actions';

const initialState: OktaWidgetConfig = {
    domain: '',
    clientId: '',
    redirectUri: '',
    issuer: '',
    forgotPassword: '',
    widgetElementId: '',
    authApiUrl: '',
};

const reducer = createReducer(initialState, builder => {
    builder.addCase(oktaWidgetConfigLoaded, (state, action) => action.payload);
});

export default reducer;
