import { createReducer } from '@reduxjs/toolkit';
import { CmsPage } from '../../cms/types';
import { pageLoaded } from './actions';

const initialState: {
    details: CmsPage;
} = {
        details: {
            title: "",
            components: []
        }
    };

const reducer = createReducer(initialState, builder => {
    builder.addCase(pageLoaded, (state, action) => {
        state.details = action.payload;
    });
});

export default reducer;
