import { combineReducers } from '@reduxjs/toolkit';
import user from './user';
import community from './community';
import viewData from './viewData';
import cart from './cart';
import navItems from './navItems';
import categories from './categories';
import myFeeds from './myFeeds';
import page from './page';
import footer from './footer';
import firstPersonData from './firstPersonDataStatus';
import okta from './oktaLogin';
import modals from './modals';
import widgets from './widgets';
import adminUtilities from './adminTools';
import userInterests from './userInterests';

export type ReduxState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
    user,
    community,
    cart,
    viewData,
    navItems,
    categories,
    myFeeds,
    page,
    footer,
    firstPersonData,
    okta,
    modals,
    widgets,
    adminUtilities,
    userInterests
});

export default rootReducer;
