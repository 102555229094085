import { createReducer } from '@reduxjs/toolkit';
import { UserInterestsResponse } from '../../types';
import { userInterestsLoaded } from './actions';

const initialState: UserInterestsResponse = {
    interests: [],
};

const reducer = createReducer(initialState, builder => {
    builder.addCase(userInterestsLoaded, (_, action) => action.payload);
});

export default reducer;
