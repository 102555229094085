import { createReducer } from '@reduxjs/toolkit';
import { helloBarLoaded } from './actions';
import { HelloBar } from '../../cms/types';

const initialState: {
    helloBar?: HelloBar;
} = {};

const reducer = createReducer(initialState, builder => {
    builder.addCase(helloBarLoaded, (state, action) => {
        state.helloBar = action.payload;
    });
});

export default reducer;
