import { createReducer } from '@reduxjs/toolkit';
import { CategoriesResponse } from '../../types';
import { categoriesLoaded } from './actions';

const initialState: {
    items?: CategoriesResponse[]
} = {};

const reducer = createReducer(initialState, builder => {
    builder.addCase(categoriesLoaded, (state, action) => {
        state.items = action.payload;
    });
});

export default reducer;
