import { createReducer } from '@reduxjs/toolkit';
import { firstPersonDataStatusLoaded } from './actions';

const initialState: {
    displayMissingEmailModal: boolean;
    allowedDomains: string[];
} = { displayMissingEmailModal: false, allowedDomains: [] };

const reducer = createReducer(initialState, builder => {
    builder.addCase(firstPersonDataStatusLoaded, (state, action) => {
        state.displayMissingEmailModal = action.payload.displayEmailPrompt;
        state.allowedDomains = action.payload.allowedDomains ?? [];
    });
});

export default reducer;
