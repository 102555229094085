import { createReducer } from '@reduxjs/toolkit';
import { viewDataLoaded } from './actions';
import { ViewData, Community, User, UserConsentData, ObfuscatedUserIds } from '../../types';

const initialState: ViewData = {
    isInternationalUser: false,
    isFavoritesEnabled: false,
    isSendReferralEnabled: false,
    showRewardsHistoryPage: false,
    authenticationType: 'None',
    currentCommunity: {} as Community,
    user: {} as User,
    obfuscatedUserIds: {} as ObfuscatedUserIds,
    cookieConsent: false,
    isEU: false,
    userConsentData: {} as UserConsentData,
    isCmsPage: false,
    isAdmin: false,
    displayTutorial: false,
    displayInterests: false,
}

const reducer = createReducer(initialState, builder => {
    builder.addCase(viewDataLoaded, (state, action) => action.payload);
});

export default reducer;
