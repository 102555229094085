import { createReducer } from '@reduxjs/toolkit';
import { communityLoaded } from './actions';
import { Community } from '../../types';

const initialState: {
    communityInfo?: Community;
} = {};

const reducer = createReducer(initialState, builder => {
    builder.addCase(communityLoaded, (state, action) => {
        state.communityInfo = action.payload;
    });
});

export default reducer;
