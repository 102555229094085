import { createReducer } from '@reduxjs/toolkit';
import { FooterContent } from '../../types';
import { footerLoaded } from './actions';

const initialState: FooterContent = {
    categories: [],
    communityNameAndDescriptor: '',
    showDisclaimer: false,
    showPrivacyBanner: false,
    disclaimer: '',
    merchants: []
};

const reducer = createReducer(initialState, builder => {
    builder.addCase(footerLoaded, (state, action) => action.payload);
});

export default reducer;
